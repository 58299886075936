




























































import Vue from "vue";
import WebAPIService from "../models/WebAPIService";

class TrackNameItem {
  trackName: string;
  trackNameDescription: string;

  constructor(trackName: string, trackNameDescription: string) {
    this.trackName = trackName;
    this.trackNameDescription = trackNameDescription;
  }
}

class RankingItem {
  id: number;
  rank: number;
  trackName: string;
  lapTime: string;
  driverName: string;
  machineName: string;
  tireName: string;
  comment: string;

  zeroPadding(num: number, length: number): string {
    return ("0000000000" + num).slice(-length);
  }

  constructor(
    id: number,
    rank: number,
    trackName: string,
    lapTime: number,
    driverName: string,
    machineName: string,
    tireName: string,
    comment: string
  ) {
    // id
    this.id = id;

    // rank
    this.rank = rank;

    // トラック名
    this.trackName = trackName;

    // ラップタイムを文字列形式で格納
    const dateTime = new Date(1900, 1, 1, 0, 0, 0, 0);
    dateTime.setSeconds(Math.round(lapTime * 100) / 100);
    const minute = Math.trunc(lapTime / 60);
    const seconds = Math.trunc(lapTime - 60 * minute);
    const milliseconds = Math.trunc((lapTime - Math.trunc(lapTime)) * 1000);
    this.lapTime =
      this.zeroPadding(minute, 2) +
      " : " +
      this.zeroPadding(seconds, 2) +
      " . " +
      this.zeroPadding(milliseconds, 3);

    // ドライバ名
    this.driverName = driverName;
    if (this.driverName == "") {
      this.driverName = "<<ドライバ名未登録>>";
    }

    this.machineName = machineName;
    if (this.machineName == "") {
      this.machineName = "<<車種未登録>>";
    }

    // タイヤ
    this.tireName = tireName;
    if (this.tireName == "") {
      this.tireName = "<<タイヤ情報未登録>>";
    }

    // コメント
    this.comment = comment;
    if (this.comment == "") {
      this.comment = "<<コメントなし>>";
    }
  }
}

export default Vue.extend({
  name: "Ranking",
  components: {},
  methods: {
    onChangeSelectedTrackName(): void {
      WebAPIService.getRanking(this.selectedTrackName.trackName)
        .then((res) => {
          this.rankingRecords.splice(0);

          if (res.result != null && res.result.length > 0) {
            let id = 1;
            let rank = 0;
            let lastItemLapTime = 0;
            for (const item of res.result) {
              if (lastItemLapTime == 0 || lastItemLapTime < item.lapTime) {
                rank++;
              }

              this.rankingRecords.push(
                new RankingItem(
                  id,
                  rank,
                  item.trackName,
                  item.lapTime,
                  item.driverName,
                  item.machineName,
                  item.tireName,
                  item.comment
                )
              );

              lastItemLapTime = item.lapTime;
              id++;
            }
          } else {
            this.rankingRecords.push(
              new RankingItem(1, 1, "", 0, "", "", "", "")
            );
          }
        })
        .catch((res) => {
          this.rankingRecords.splice(0);
          console.error(res);
        });
    },
  },
  data: () => ({
    trackNames: [] as Array<TrackNameItem>,
    selectedTrackName: {} as TrackNameItem,
    rankingRecords: [] as Array<RankingItem>,
  }),
  mounted() {
    WebAPIService.getTrackNames()
      .then((res) => {
        if (res.result != null) {
          for (const item of res.result) {
            this.trackNames.push(
              new TrackNameItem(item.trackName, item.trackNameDescription)
            );
          }
        }
      })
      .catch((res) => {
        console.error(res);
      });
  },
});
